<template>
  <div class="ai-page">
    <Header :showTop="false"></Header>
    <div class="total">
      <div class="left">
        <div class="spanStyle"></div>
        <div class="button">
          <div class="newSession" @click="newContent">
            <i class="el-icon-circle-plus"></i>&nbsp;&nbsp;{{
              $t("ai.newChat")
            }}
          </div>
        </div>
        <div class="spanStyle"></div>
        <div style="display: flex; justify-content: space-between">
          <div class="history">{{ $t("ai.historyChat") }}</div>
        </div>

        <div v-if="communicateHistory.length !== 0" class="historycontend">
          <li
            v-for="(communicateHistorys, index) in communicateHistory"
            :key="index"
          >
            <el-button
              :class="{ active: communicateHistorys.isClicked }"
              class="historyCard"
              @click="HisCommunicate(communicateHistorys)"
            >
              <div style="display: flex">
                <div class="Historyimg">
                  <img v-if="userInfo.avatar" :src="userInfo.avatar ? baseURL + userInfo.avatar : '@/assets/avator.png'" />
                </div>
                <div class="HistoryCon">
                  {{ communicateHistorys.qaQuery }}
                </div>
                <div
                  class="historydelete"
                  @click.stop="deleteHistory(communicateHistorys)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </el-button>
          </li>
        </div>
        <div v-else class="historycontendNo">
          <div class="talkNo"><img src="@/assets/AIAnswer/noTalk.png" /></div>
          {{ $t("ai.noHistory") }}
        </div>
      </div>
      <div class="right">
        <div class="news1">
          <div class="content">
            <div ref="messageContainer" class="communicate">
              <div v-if="historynormal === 0" class="atalk">
                <div v-if="messages.length !== 0" class="AIphoto"></div>
                <div v-else class="AIphotoMove"></div>
                <span id="asay" class="answerPrimary">
                  <div id="whose1" class="talk" style="width: 100%">
                    <div style="text-align: left; margin: 0 auto">
                      <div class="span" style="width: 100%">
                        <div style="display: flex">
                          <div style="display: flex; text-align: center">
                            <!--                <div style="height:25px;text-align: right;"><img src="@/assets/AIAnswer/up.png"></div>-->
                            <div class="title">
                              &nbsp;<span style="font-weight: bold;">{{
                                $t("ai.aiName")
                              }}</span>
                              · {{ $t("ai.tip") }}~
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="spanStyle"></div>
                      <div class="normal" >
                        <div style="display: flex; text-align: center">
                          <div style="height: 1.2vw; text-align: right">
                            <img src="@/assets/AIAnswer/up.png" />
                          </div>
                          <div class="title">
                            &nbsp;{{ $t("ai.serviceability") }}
                          </div>
                        </div>
                        <div class="Answer">
                          <!--AI对话-->
                          <!-- @click="talk(2)" -->
                          <div :class="serveActive(2)">
                            <div style="display: flex">
                              <div style="display: flex; text-align: center">
                                <div style="height: 1.2vw; text-align: right">
                                  <img
                                    src="@/assets/AIAnswer/aiCommunicate.png"
                                  />
                                </div>
                                <div class="title">
                                  &nbsp;<span style="font-weight: bold"
                                    >【{{ $t("ai.aiChat") }}】</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="title" style="margin-top: 1vh">{{ $t("ai.aiChatTip") }}</div>
                          </div>
                          <!--              文档理解-->
                          <!-- @click="talk(0)" -->
                          <div :class="serveActive(0)">
                            <div style="display: flex">
                              <div style="display: flex; text-align: center">
                                <div style="height: 1.2vw; text-align: right">
                                  <img src="@/assets/AIAnswer/file.png" />
                                </div>
                                <div class="title">
                                  &nbsp;<span style="font-weight: bold"
                                    >【{{ $t("ai.newsSummary") }}】</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="title" style="margin-top: 1vh">
                              {{ $t("ai.newsSummaryTip") }}
                            </div>
                          </div>
                          <!--本子知识库-->
                          <div :class="serveActive(1)">
                            <div style="display: flex">
                              <div style="display: flex; text-align: center">
                                <div style="height: 1.2vw; text-align: right">
                                  <img src="@/assets/AIAnswer/research.png" />
                                </div>
                                <div class="title">
                                  &nbsp;<span style="font-weight: bold"
                                    >【{{ $t("ai.membership") }}】</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="title" style="margin-top: 1vh">
                              {{ $t("ai.membershipTip") }}
                              <a
                                @click="goActivateMembership()"
                                href="javascript:void(0);"
                                >{{ $t("ai.membershipBtn") }}</a
                              >
                            </div>
                          </div>

                          <!--文档同步-->
                          <div class="serve">
                            <div style="display: flex">
                              <div style="display: flex; text-align: center">
                                <div style="height: 1.2vw; text-align: right">
                                  <img src="@/assets/AIAnswer/secret.png" />
                                </div>
                                <div class="title">
                                  &nbsp;<span style="font-weight: bold"
                                    >【{{ $t("ai.customizedService") }}】</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="title" style="margin-top: 1vh">
                              {{ $t("ai.customizedServiceTip")
                              }}<a
                                @click="getMemberById()"
                                href="javascript:void(0);"
                                >{{ $t("ai.customizedServiceBtn") }}</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>

              <div class="talk-show">
                <div
                  v-for="message in messages"
                  :key="message.id"
                  :class="message.sender === 'user' ? 'user' : 'ai'"
                  class="message"
                >
<!--                  用户-->
                  <div v-if="message.sender === 'user'">
                    <div class="btalk">
                      <div style="display: flex">
                        <div>
                          <img
                            :src="avator"
                            alt="User Avatar"
                            class="userPhoto"
                          />
                        </div>
                        <div style="width: 90%">
                          <span
                            ><div class="arrow-content">{{ message.text }}</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
<!--                  ai回答-->
                  <div v-else>

                    <div class="atalk">
                      <div v-if="message.ok === 0" class="AIphotoMove"></div>
                      <div
                        v-if="message.ok === 1 || message.ok === 2"
                        class="AIphoto"
                      ></div>
                      <div id="asay" class="answer">
<!--                        <div v-if="message.resource.length !== 0">-->
<!--                          <div v-if="message.id !== ''">-->
<!--                            <div class="tips">-->
<!--                              {{ $t('ai.findDocTip1') }}-->
<!--                              {{ message.resource.length }}{{ $t('ai.findDocTip2') }}：-->
<!--                            </div>-->
<!--                          </div>-->

<!--                          <div v-if="message.id !== ''" class="con_que">-->
<!--                            <div-->
<!--                              v-for="(item2, index) in message.resource"-->
<!--                              :key="index"-->
<!--                            >-->
<!--                              <div @click="clickRobot(item2)">-->
<!--                                <div>-->
<!--                                  <span style="color: #111111"-->
<!--                                    >{{ $t('ai.findDocTip3') }}&nbsp;<span v-if="$i18n.locale != 'en'"-->
<!--                                      style="color: #3163c5"-->
<!--                                      >{{ index + 1 }}</span-->
<!--                                    >&nbsp;{{ $t('ai.findDocTip4') }}：</span-->
<!--                                  ><span class="czkj-question-msg">-->
<!--                                    {{ item2.title }}</span-->
<!--                                  >-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->

                        <div id="whose1" class="talk">
<!--                          <div v-html="message.answer"></div>-->
                          <vue-markdown :source="message.answer">

                          </vue-markdown>
                          <!--                        {{ message.answer }}-->
                        </div>
                        <div v-if="message.ok === 1" class="afterAnswer">
                          <div style="display: flex">
                            <div class="buttonCopy">
                              <button
                                v-if="isTextDisplayed && message.copy === 0"
                                id="copy"
                                class="copy"
                                @click="copy(message)"
                              >
                                <span
                                  class="hover-text"
                                  style="display: none"
                                ></span>
                              </button>
                              <button
                                v-if="isTextDisplayed && message.copy === 1"
                                id="copy"
                                class="copyReally"
                                @click="copy(message)"
                              >
                                <span
                                  class="hover-text"
                                  style="display: none"
                                ></span>
                              </button>
                            </div>
                            <div class="buttonCopy">
                              <button
                                v-if="
                                  isTextDisplayed && message.evaluationI === '2'
                                "
                                id="deny"
                                class="denyReally"
                                @click="denyChange(message)"
                              >
                                <span
                                  class="hover-text"
                                  style="display: none"
                                ></span>
                              </button>
                              <button
                                v-else
                                id="deny"
                                class="deny"
                                @click="denyChange(message)"
                              >
                                <span
                                  class="hover-text"
                                  style="display: none"
                                ></span>
                              </button>
                            </div>
                          </div>
                          <!-- <div class="dataSource">数据来源：内部数据</div> -->
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="talk_input">
              <!-- <div class="chat-num-tip"  v-if="userInfo.userId">
                <span>{{ $t('ai.chatNumTip1') }}</span>
                <span style="color: red">
                  {{ countSum.freeNum + countSum.payNum }}
                </span>
                <span>{{ $t('ai.chatNumTip2') }}</span>
                <span style="color: red">
                  {{ countSum.freeUse + countSum.payUse }}
                </span>
                <span>{{ $t('ai.chatNumTip3') }}</span>
                <span>{{ $t('ai.chatNumTip4') }}</span>
                <span class="buy" @click="goActivateMembership">{{ $t('ai.buy') }}</span>
              </div> -->
              <div class="input_container">
                <textarea
                  id="text"
                  ref="inputArea"
                  v-model="newMessage"
                  :rows="1"
                  class="talk_word"
                  maxlength="3000"
                  @input="resizeTextarea"
                  @blur="blurHandle"
                  @keydown.enter.prevent
                  @keyup.enter="sendMessage()"
                ></textarea>
                <div class="talk_button">
                  <el-button
                    :disabled="isSendingMessage"
                    class="talk_sub"
                    type="primary"
                    @click="sendMessage()"
                  ></el-button>
                </div>
                <!-- <div
                  v-if="hasPlaceholder"
                  class="self-placeholder-box"
                  @click="hidePlaceholder()"
                >
                  <div class="self-placeholder-text">
                    <a
                      v-if="!this.userInfo.userId"
                      href="javascript:void(0)"
                      @click.prevent="login"
                      >{{ $t("common.login") }}</a
                    >{{
                      $t(this.userInfo.userId ? "ai.aiInput" : "ai.aiInputLoginTip")
                    }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <el-dialog
          :title="$t('ai.contactMethod')"
          :visible.sync="centerDialogVisible"
          width="300px"
          close-on-click-modal
          class="connectForm"
          :before-close="handleClose"
          top="40vh"
        >
          <span style="margin: 5px">{{$t('ai.contact')}}：{{ contact.name }}</span
          ><br /><br />
          <span style="margin: 5px">{{$t('ai.phone')}}：{{ contact.phone }}</span
          ><br /><br />
          <span style="margin: 5px">{{$t('ai.email')}}：{{ contact.email }}</span>
          <span slot="footer" class="dialog-footer"></span>
        </el-dialog>

        <el-dialog :visible.sync="denyformShow" class="denyForm">
          <el-form
            ref="denyForm"
            :model="denyForm"
            style="width: 100%; height: fit-content"
          >
            <div
              style="
                background-color: var(--main-color);
                align-content: center;
                line-height: 35px;
                /*border-top-left-radius: 13px;*/
                /*border-top-right-radius: 13px;*/
              "
            >
              <div style=" color: white; font-size: 1vw;text-align: center;padding: 1vh 0">
                {{ $t("ai.qaAdvice") }}
              </div>
            </div>
            <div style="margin-left: 5%; margin-right: 5%; margin-top: 4%">
              <el-form-item
                :label="$t('ai.qaAdviceQ')"
                style="font-weight: bold"
              >
                <el-input
                  v-model="denyForm.question"
                  class="questioninput"
                  disabled
                  style="font-size:0.7vw;"
                ></el-input>
              </el-form-item>

              <el-form-item
                :label="$t('ai.qaAdviceA')"
                style="font-weight: bold; margin-top: 3vh"
              >
                <el-input
                  v-model="denyForm.answer"
                  class="ansinput"
                  :rows="20"
                  style="border: black 2px solid;font-size:0.7vw;"
                  type="textarea"
                ></el-input>
              </el-form-item>

              <div
                class="buttons-container"
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  padding-right: 15px;
                  padding-bottom: 15px;
                  position: absolute;
                "
              >
                <el-form-item>
                  <el-button
                    style="
                      color: white;
                      border-radius: 10px;
                      font-weight: 800;
                      width: 10vh;
                      margin-right: 5px;
                      background-color: var(--main-color);
                    "
                    @click="submitDeny()"
                    >{{ $t("common.submit") }}
                  </el-button>
                  <el-button
                    style="
                      color: white;
                      font-weight: 800;
                      border-radius: 10px;
                      width: 10vh;
                      background-color: gray;
                    "
                    @click="resetDeny()"
                  >
                    {{ $t("common.reset") }}
                  </el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </el-dialog>

        <el-dialog
          :title="$t('ai.chatNumUseUpTitle')"
          :visible.sync="kindtips"
          width="450px"
          class="connectForm"
          :before-close="handleClose"
          top="40vh"
        >
          <span style="margin: 5px"> {{ $t("ai.chatNumUseUpTip") }}~ </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="kindtips = false">{{
              $t("common.cancel")
            }}</el-button>
            <router-link to="/member">
              <el-button
                size="mini"
                style="
                  background-color: #bd0022;
                  border: none;
                  margin: -3px 5px;
                "
                type="primary"
              >
                {{ $t("ai.activate") }}
              </el-button>
            </router-link>
          </span>
        </el-dialog>

        <bottomTip></bottomTip>
      </div>
    </div>
    <LoginTip
      :dialogVisible="loginDialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import bottomTip from "@/components/AIAnswer/bottomTip.vue";
import LoginTip from "@/components/LoginTip.vue";
import mixins from "@/utils/mixins.js";
import { mapState } from 'vuex'
// import marked from'marked'; // 引入 marked 库，用于解析 markdown 为 HTML
import { getAuthorize } from "@/api/user";
import { getNewsDetail } from "@/api/index";
import MarkdownIt from 'markdown-it';
import VueMarkdown from "vue-markdown/src/VueMarkdown";
let a = "中国石油，即中国石油天然气集团有限公司（简称“中石油”，英文名为China National Petroleum Corporation，缩写为CNPC），是一家具有深厚历史和广泛影响力的能源企业。以下是对其详细介绍：\n" +
    "\n" +
    "### 一、公司概况\n" +
    "\n" +
    "中石油成立于1998年，是国家授权投资机构和国家控股公司，总部位于北京。它是以国内外油气的勘探和生产、天然气管道储运、炼油加工、工程技术建设、油气设备生产以及国际贸易等业务为主的综合性能源公司，是国际上重要的油气生产商和供应商之一。\n" +
    "\n" +
    "### 二、业务范围\n" +
    "\n" +
    "中石油的业务范围广泛，涵盖了油气勘探、开采、生产、运输和销售等多个环节。具体来说：\n" +
    "\n" +
    "1. **油气勘探与开发**：中石油拥有强大的勘探团队和先进的勘探技术，在全球范围内寻找和开发油气资源。其勘探范围涵盖陆地和海洋，通过地震勘探、地球物理勘探等多种手段，精准定位油气藏。\n" +
    "2. **炼油与销售**：中石油对原油和天然气进行加工处理，生产出符合市场需求的石油产品和天然气产品。公司拥有庞大的销售网络和完善的销售体系，能够为客户提供全方位的油气产品和服务，涵盖成品油、天然气、化工产品等多个领域。\n" +
    "3. **天然气管道储运**：中石油建立完善的油气运输网络，其中管道运输是公司的主要运输方式，具有运输量大、成本低、安全性高等优点。\n" +
    "4. **工程技术服务**：中石油旗下拥有多家工程技术服务公司，专注于提供石油勘探、钻井、测井等专业技术服务。\n" +
    "5. **国际贸易**：中石油积极参与国际能源市场的竞争与合作，与全球多家能源公司进行贸易往来，保障公司的原材料供应和产品销售。\n" +
    "\n" +
    "### 三、企业实力\n" +
    "\n" +
    "中石油在油气领域拥有显著的实力和优势：\n" +
    "\n" +
    "1. **生产能力**：中石油作为国内最大的石油、天然气生产和销售企业，拥有强大的原油年生产能力和高效的天然气年生产能力。\n" +
    "2. **资源储备**：中石油的石油和天然气资源储备丰富，且分布广泛。在石油资源方面，主要集中在东北、华北、新疆、甘陕地区以及渤海和南海等区域；在天然气资源方面，塔里木、四川、鄂尔多斯等地是其主要分布区域。\n" +
    "3. **国际化布局**：中石油在全球32个国家和地区开展油气投资业务，积极拓展海外炼油、化工、天然气等领域，与多个国家和地区的石油天然气公司建立了广泛的合作关系。\n" +
    "\n" +
    "### 四、企业文化与社会责任\n" +
    "\n" +
    "中石油秉持诚信、创新、业绩、和谐与安全的企业文化理念，致力于为客户成长增动力，为人民幸福赋新能。在社会责任方面，中石油积极履行绿色发展、奉献能源的使命，通过制定和实施独具特色的价值引领型社会责任管理模式，将社会责任全面融入公司运营。在环境保护、可持续发展和社会公益等方面都做出了显著贡献。\n" +
    "\n" +
    "综上所述，中国石油天然气集团有限公司是一家具有深厚历史底蕴、广泛业务范围和强大企业实力的综合性国际能源公司。"
let b = a.split('')
console.log(b)
export default {
  name: "Home",
  components: {
    bottomTip,
    LoginTip,
    Header,
    VueMarkdown
  },
  mixins: [mixins],
  data() {
    return {
      qwert:'',
      tempText:"",
      net: 0,
      denyForm: {
        id: "",
        question: "",
        answer: "",
      },
      denyformShow: false,
      hasPlaceholder: true,
      primary: "",
      submitForm: {
        id: "",
        question: "",
        answer: "",
        primary: "",
      },
      uniqueId: "",
      kindtips: false,
      active: 2,
      fileLocation: "",
      fileId: "",
      fileName: "",
      centerDialogVisible: false,
      contact: {},
      memberId: 2,
      countSum: { freeNum: "", freeUse: "", payNum: "", payUse: "" },
      communicateHistory: [],
      messages: [],
      answerAIID:0,
      stopAnswer:0,
      // userInfo: "",
      isTextDisplayed: false,
      isSendingMessage: false,
      newMessage: "",
      historynormal: 0,
      avator: require("@/assets/AIAnswer/userLogo.png"),
      model: false,
      // avator: require("../../assets/AIAnswer/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: "0123456789ABCDEF",
      upload: false,
      about: false,
      uploadmore: false,
      groupedItems: [],
      history: [],
      answers: {
        0: "ai.aiChatTip",
        2: "ai.newsSummaryTip",
      },
      loginDialogVisible: false,
      answerAI:''
    };
  },
  mounted() {
    this.SSEinite()
  },
  computed: {
    serveActive() {
      return (val) => (this.active === val ? "serveActive" : "serve");
    },
    ...mapState({
      'access_token': 'access_token',
      'userInfo': 'user'
    })
  },
  created() {
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    // this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.historylist();
    this.getQuestionCount();


  },
  watch: {
    messages(newValue) {
      console.log("jiank");
      console.log(newValue);
      this.$forceUpdate();
    },
    "$store.state.user"(newValue) {
      this.user = newValue;
      this.countSum = { freeNum: this.user.freeNum, freeUse: this.user.freeUse, payNum: this.user.payNum, payUse: this.user.payUse }
    },
  },
  methods: {
    async SSEinite() {
      const self = this
      this.$axios.get("/infochat").then((response) => {
      // this.$axios.get("http://localhost:25145/").then((response) => {
        this.talkid = response.data["id"]
        // alert(this.talkid)

        this.eventSource = new EventSource('/infochat/stream?channel=' + response.data["id"]);
        this.eventSource.addEventListener('message_event', (event) => {
          var data = JSON.parse(event.data);
          console.log("服务器说: " + data.message);
          if(data.message!=="" && data.message!=="--stop"){
            // 正在输入
            this.stopAnswer=1
            console.log("服务器说this.answerAI: " + this.answerAI);
          }
          else{
            this.stopAnswer=0
          }
          if (!this.messages) {
            this.messages = [];
          }

          const index = this.messages.findIndex(
              (message) => message.id === this.answerAIID && message.sender === "ai"
          );
          if (index !== -1) {
            // // 逐字显示回答
            // this.$set(this.messages[index], "id", 12366); // 更改ID
            // vm.$set(vm.messages[index], "answer", response.data.answer); // 更改ID

            if(this.messages[index].answer===this.$t('ai.generating')){
              this.messages[index].answer=""
            }
            if(data.message!=="" && data.message!=="--stop"){
              // 正在输入
              this.$set(this.messages[index], "answer", this.messages[index].answer + data.message);
            }

            // vm.printAnswerMessage('resource', vm, index);
            // vm.$set(vm.messages[index], 'resource', resindex);
            this.$nextTick(() => {
              // 在下一个tick时滚动到底部
              const container = this.$refs.messageContainer;
              container.scrollTop = container.scrollHeight;
            });
            // console.log("huidu ")
            // console.log(vm.messages)
          }
          console.log("正在输入1: ", this.stopAnswer);
          self.answer = data.message

        }, false);
        if (this.$route.query.question !== undefined && this.$route.query.question!== ''){
          this.newMessage= this.$route.query.question;
          this.sendMessage()
        }

      })


      /*      this.eventSource.onmessage = (event) => {
              print("123123213123123")
              const data = JSON.parse(event.data)//将字符串转换成json格式
              if (data.number !== 'done') {
                this.answer.push(data.number);
              } else {
                this.eventSource.close();
              }
            };

            this.eventSource.onerror = (error) => {
              if (this.eventSource.readyState === EventSource.CLOSED) {
                console.log('Connection to server closed');
              } else {
                console.error('Error occurred:', error);
              }
            };*/
    },
    setb(){
      let i = 0
      while (i < b.length){

        setTimeout(() => {
          this.qwert+=b[i]
          i++
        }, 100)
      }
    },
    handleClose(done) {
      done();
    },
    removeHtmlTags(htmlString) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;
      return tempDiv.textContent || tempDiv.innerText || "";
    },
    resizeTextarea() {
      const textarea = document.getElementById("text");
      textarea.style.height = "auto"; // 重置高度，使其自动调整
      textarea.style.height = textarea.scrollHeight + "px"; // 设置为内容高度
      if (this.newMessage.trim() === "") {
        textarea.style.height = "22px"; // 当内容为空时，设置为最小高度
      }
      textarea.scrollTop = 0; // 将滚动条置顶
      if (this.newMessage.length >= 3000) {
        this.$emit("input", this.newMessage.slice(0, 3000));
        this.$message.warning(this.$t('ai.typeLimitTip'));
      }
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, "showDeleteButton", true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, "showDeleteButton", false);
    },
    //联系人信息查询
    getMemberById() {
      this.$axios
        .get("/api/member/getGpt/" + this.memberId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            // 处理响应数据
            this.contact = response.data.data;
            // 这里可以根据返回的数据进行相应的操作
            console.log(this.contact);
            this.centerDialogVisible = true;
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("Error fetching member data:", error);
          // 这里可以处理错误信息
        });
    },

    //问答次数查询
    getQuestionCount() {
      if (!this.userInfo.userId) return;
      this.$axios
        .post(
          "/api/user/getNum",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((response) => {
          // 处理响应数据
          if (response.data.code == 200) {
            this.countSum = response.data.data;
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("Error fetching member data:", error);
          // 这里可以处理错误信息
        });
    },
    //修改次数
    updateQaNum() {
      this.$axios
        .post(
          "/api/user/updateNum",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((response) => {
          // 处理响应数据
          if (response.data.code == 200) {
            this.countSum = response.data.data;
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("Error fetching member data:", error);
          // 这里可以处理错误信息
        });
    },

    // 文章索引
    async clickRobot(item) {
      let map = {
        1: 'technology',
        4: 'news',
        2: 'report',
        3: 'opportunity'
      }

      let res = await getNewsDetail(item.id);

      try {
        this.$router.push('/'+ map[res.data.primaryTypeid] +'/' + item.id);
      } catch (error) {
        console.error(error)
      }

      // this.$router.push({
      //   path: "/articalDetail",
      //   query: {
      //     Typeid: item.primaryTypeid,
      //     newId: item.id,
      //   },
      // });
    },
    // 新建对话
    newContent() {
      this.historynormal = 0;
      this.active = 2;
      this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
      this.messages = [];
      this.count = 0;
      this.isSendingMessage = false;
      this.historylist();
    },
    // 历史记录轮次
    historylist() {
      if (!this.userInfo.userId) return;
      this.$axios
        .get("api/qa/selectAll", {
          params: {
            uid: this.userInfo.userId,
            isDelete: 0,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: parseInt(this.userInfo.userId),
          },
        })
        .then((res) => {
          this.communicateHistory = res.data;
          for (var i = 0; i < this.communicateHistory.length; i++) {
            const currentDate = new Date(this.communicateHistory[i].createTime);
            const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
            const day = ("0" + currentDate.getDate()).slice(-2);
            const hours = ("0" + currentDate.getHours()).slice(-2);
            const min = ("0" + currentDate.getMinutes()).slice(-2);
            const formattedDate = `${month}-${day} ${hours}:${min}`;
            this.communicateHistory[i].createTime = formattedDate;
            this.communicateHistory[i].isClicked = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 删除历史
    deleteHistory(item) {
      this.$axios
        .delete("api/qa/" + item.roundId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res);
          this.historylist();
          this.newContent();
        });
    },
    // 详细的历史记录
    HisCommunicate(communicateHistory) {
      this.historynormal = 1;
      // this.historylist()
      for (var i = 0; i < this.communicateHistory.length; i++) {
        this.communicateHistory[i].isClicked = false;
      }

      communicateHistory.isClicked = true;
      this.uniqueId = communicateHistory.roundId;
      this.messages = [];

      this.$axios
        .get("/api/qa/selectByRoundId/" + this.uniqueId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.isTextDisplayed = true;

          const mes = res.data;
          console.log("详情", mes);
          for (var i = 0; i < mes.length; i++) {
            this.messages.push({
              id: mes[i].id + "id",
              sender: "user",
              text: mes[i].qaQuery,
            });

            if (mes[i].qaEvaluate) {
              mes[i].qaEvaluate = 1;
            } else {
              mes[i].qaEvaluate = 0;
            }

            this.messages.push({
              // 使用保存的vm
              ok: 1,
              id: mes[i].id,
              sender: "ai",
              copy: 0,
              resource: "",
              answer: mes[i].qaAnswers,
              deny: mes[i].qaEvaluate,
              question: mes[i].qaQuery,
            });
          }
        });
    },
    talk(type) {
      this.uniqueId = "";
      this.messages = [];
      this.active = type;
      // this.historylist()
      this.messages.push({
        ok: 0,
        id: "11111",
        sender: "ai",
        copy: 0,
        answer: this.$t(this.answers[type]),
        resource: "",
      });
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    goActivateMembership() {
      if (this.userInfo.userId) {
        this.$router.push("/member");
      } else {
        this.loginDialogVisible = true;
      }
    },
    hideDialog() {
      this.loginDialogVisible = false;
    },
    sendMessage() {
      console.log("正在输入1sendMessage: ", this.stopAnswer);
      if (this.stopAnswer===0){
        this.stopAnswer=1
        this.answerAIID++
        this.messages.push({
          // id: this.uniqueId,
          sender: "user",
          text: this.newMessage,
        });

        const temptext=this.newMessage
        this.newMessage=""
        //       // 先添加“稍后再试”消息
        this.messages.push({
          id: this.answerAIID,
          ok: 0,
          sender: "ai",
          answer: this.$t('ai.generating'),
          resource: "",
          copy: 0,
          deny: 0,
          question: this.question,
        });
        this.$axios.post("/infochat/get_answer2", {
          question: temptext,
          client_id: this.talkid
        }).then((res)=>{
          console.log(res)
          this.$nextTick(() => {
            // 在下一个tick时滚动到底部
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        })

      }
      else{
        this.$notify.warning({
                  title: this.$t('Tips'),
                  message: this.$t('ai.generating'),
                  showClose: true,
                })
      }



    },
    // sendMessage() {
    //   this.$axios.post("http://localhost:25145/get_answer2",{
    //     question:"你好",
    //     client_id:this.talkid
    //   }).then()
    //   // this.setb();
    //   //判断问答次数是否大于0
    //   // if (this.countSum.freeNum + this.countSum.payNum <= 0) {
    //   //   this.kindtips = true;
    //   //   // 或者触发其他逻辑，例如显示一个模态框等
    //   // } else {
    //     // 找到所有满足条件的消息
    //     const messagesToUpdate = this.messages.filter(
    //       (message) =>
    //         message.id === "00000" &&
    //         message.sender === "ai" &&
    //         message.ok === 0
    //     );
    //
    //     // 遍历所有找到的消息并更新它们的ok属性
    //     messagesToUpdate.forEach((message) => {
    //       this.$set(message, "ok", 2);
    //     });
    //
    //     // 找到所有满足条件的消息
    //     const mes = this.messages.filter(
    //       (message) =>
    //         message.id === "11111" &&
    //         message.sender === "ai" &&
    //         message.ok === 0
    //     );
    //
    //     // 遍历所有找到的消息并更新它们的ok属性
    //     mes.forEach((message) => {
    //       this.$set(message, "ok", 2);
    //     });
    //
    //     // 清除之前的定时器
    //     if (this.timer) {
    //       clearTimeout(this.timer);
    //     }
    //     this.timer = setTimeout(() => {
    //       this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
    //       this.messages = [];
    //       this.count = 0;
    //       this.isSendingMessage = false;
    //       this.historylist();
    //       this.$notify.warning({
    //         title: this.$t('ai.tipTitle'),
    //         message: this.$t('ai.timeoutTip'),
    //         showClose: true,
    //       });
    //     }, 1800000); // 等于 60,000*30 毫秒
    //
    //     // 禁用发送按钮
    //     this.isSendingMessage = true;
    //     this.count++;
    //
    //     if (this.uniqueId === "" || this.uniqueId === undefined) {
    //       this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
    //     }
    //
    //     // TODOS:
    //     // this.HisRecord( this.uniqueId)
    //
    //     if (this.newMessage.length !== 0) {
    //       // 问题
    //       this.question = this.newMessage;
    //       this.messages.push({
    //         id: this.uniqueId,
    //         sender: "user",
    //         text: this.newMessage,
    //       });
    //
    //       // 先添加“稍后再试”消息
    //       this.messages.push({
    //         id: "",
    //         ok: 0,
    //         sender: "ai",
    //         answer: this.$t('ai.generateTip'),
    //         resource: "",
    //         copy: 0,
    //         deny: 0,
    //         question: this.question,
    //       });
    //
    //       if (this.count === 21) {
    //         this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
    //         this.messages = [];
    //         this.count = 0;
    //         this.isSendingMessage = false;
    //         this.historylist();
    //         this.$notify.warning({
    //           title: this.$t('ai.tipTitle'),
    //           message: this.$t('ai.unabilityTip'),
    //           showClose: false,
    //         });
    //       } else {
    //         var self = this;
    //         this.newMessage = "";
    //         this.resizeTextarea();
    //
    //         setTimeout(() => {
    //           var vm = this; // 保存this
    //           //TODO: 需要区分中英文，以及需要文章详情页的路由功能确定
    //           this.$axios
    //             .post(
    //               `/py/${this.trans('get_answer2,get_answerEn')}`,
    //               // `/api/py/get_answer2`,
    //               {
    //                 question: this.question,
    //                 round_id: this.uniqueId,
    //                 userId: '1', //this.userInfo.userId.toString(),
    //               },
    //               {
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   Authorization: this.token,
    //                 },
    //               }
    //             )
    //             .then(async function (response) {
    //               // self.updateQaNum();
    //               var resindex = [];
    //               if(self.$i18n.locale == 'zh'){
    //                 for (var i = 0; i < response.data.titles.length; i++) {
    //                   resindex.push({
    //                     index: i + 1,
    //                     id: response.data.ids[i],
    //                     title: response.data.titles[i],
    //                   });
    //                 }
    //               }
    //               vm.isSendingMessage = false;
    //               // 查找索引以替换“稍后再试”消息
    //               const index = vm.messages.findIndex(
    //                 (message) => message.id === "" && message.sender === "ai"
    //               );
    //               if (index !== -1) {
    //                 // 逐字显示回答
    //                 vm.$set(vm.messages[index], "id", response.data.id); // 更改ID
    //                 // this.tempText=marked(response.data.answer)
    //                 // const md = new MarkdownIt();
    //                 const tempText = response.data.answer
    //                 console.log("tempText.............",tempText)
    //                 await vm.printAnswerMessage(
    //                   tempText,
    //                   vm,
    //                   index,
    //                   resindex
    //                 );
    //                 // vm.printAnswerMessage('resource', vm, index);
    //                 // vm.$set(vm.messages[index], 'resource', resindex);
    //                 this.$nextTick(() => {
    //                   // 在下一个tick时滚动到底部
    //                   const container = this.$refs.messageContainer;
    //                   container.scrollTop = container.scrollHeight;
    //                 });
    //                 // console.log("huidu ")
    //                 // console.log(vm.messages)
    //                 vm.tempText=this.removeHtmlTags(response.data.answer)
    //               }
    //             });
    //         }, 2000); // 2000毫秒（2秒）
    //       }
    //     }
    //     else if (this.Message) {
    //       // 问题
    //       this.question = this.Message;
    //       this.messages.push({
    //         id: this.uniqueId,
    //         sender: "user",
    //         text: this.Message,
    //       });
    //
    //       // 先添加“稍后再试”消息
    //       this.messages.push({
    //         id: "",
    //         sender: "ai",
    //         answer: "Generating, please wait...",
    //         deny: 0,
    //         question: this.question,
    //         resource: "",
    //       });
    //
    //       if (this.count === 21) {
    //         this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
    //         this.messages = [];
    //         this.count = 0;
    //         this.isSendingMessage = false;
    //         this.historylist();
    //         this.$notify.warning({
    //           title: "Tips",
    //           message:
    //             "The current round of dialogue is beyond the limits of DRE's ability, we will start a new round of dialogue.",
    //           showClose: false,
    //         });
    //       } else {
    //         this.Message = "";
    //
    //         setTimeout(() => {
    //           var vm = this; // 保存this
    //           this.$axios
    //             .post(
    //               "/api/qa/get_answerEn",
    //               {
    //                 question: this.question,
    //                 round_id: this.uniqueId,
    //                 userId: '1', // this.userInfo.userId.toString(),
    //               },
    //               {
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   Authorization: this.token,
    //                 },
    //               }
    //             )
    //             .then(async function (response) {
    //               var resindex = [];
    //               for (var i = 0; i < response.data.titles.length; i++) {
    //                 resindex.push({
    //                   index: i + 1,
    //                   id: response.data.ids[i],
    //                   title: response.data.titles[i],
    //                 });
    //               }
    //               vm.isSendingMessage = false;
    //               // 查找索引以替换“稍后再试”消息
    //               const index = vm.messages.findIndex(
    //                 (message) => message.id === "" && message.sender === "ai"
    //               );
    //               if (index !== -1) {
    //                 // 逐字显示回答
    //                  vm.$set(vm.messages[index], "id", response.data.id); // 更改ID
    //                  // vm.$set(vm.messages[index], "answer", response.data.answer); // 更改ID
    //
    //                 await vm.printAnswerMessage(
    //                   response.data.answer,
    //                   vm,
    //                   index,
    //                   resindex
    //                 );
    //                 // vm.printAnswerMessage('resource', vm, index);
    //                 // vm.$set(vm.messages[index], 'resource', resindex);
    //                 this.$nextTick(() => {
    //                   // 在下一个tick时滚动到底部
    //                   const container = this.$refs.messageContainer;
    //                   container.scrollTop = container.scrollHeight;
    //                 });
    //                 // console.log("huidu ")
    //                 // console.log(vm.messages)
    //               }
    //             });
    //         }, 2000); // 2000毫秒（2秒）
    //       }
    //     } else {
    //       if (this.$i18n.locale === "zh") {
    //         this.$notify.warning({
    //           title: "提示",
    //           message: "请输入您的问题",
    //           showClose: false,
    //         });
    //       } else {
    //         this.$notify.warning({
    //           title: "Tips",
    //           message: "Please enter your question",
    //           showClose: false,
    //         });
    //       }
    //     }
    //
    //     this.$nextTick(() => {
    //       const container = this.$refs.messageContainer;
    //       container.scrollTop = container.scrollHeight;
    //     });
    //   // }
    // },
    async printAnswerMessage(message, vm, index, resindex) {
      // let currentIndex = 0;
      // let printedMessage = "";
      const printFunction = () => {
        // if (currentIndex < message.length) {
        //   // if(message[currentIndex]=="<"){
        //   //   let arrayindex=1;
        //   //   printedMessage += message[currentIndex]
        //   //   while(message[currentIndex+arrayindex]!="/"){
        //   //     printedMessage += message[currentIndex+arrayindex]
        //   //     arrayindex++
        //   //   }
        //   //   vm.messages[index].answer = printedMessage;
        //   //   currentIndex=currentIndex+1;
        //   // }
        //   // else{
        //     printedMessage += message[currentIndex];
        //     vm.messages[index].answer = printedMessage;
        //   // console.log(vm.messages[index].answer)
        //     currentIndex=currentIndex+1;
        //   // }
        //   setTimeout(printFunction, 50);
        //   this.$nextTick(() => {
        //     const container = this.$refs.messageContainer;
        //     container.scrollTop = container.scrollHeight;
        //   });
        // }
        // else {
        //   vm.$set(vm.messages[index], "resource", resindex); // 更改ID
        //   vm.$set(vm.messages[index], "ok", 1); // 更改ID
        //   vm.$set(vm.messages[index], "deny", 0); // 更改ID
        //   // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
        //   this.isTextDisplayed = true;
        //   this.$nextTick(() => {
        //     const container = this.$refs.messageContainer;
        //     container.scrollTop = container.scrollHeight;
        //   });
        // }
        vm.$set(vm.messages[index], "resource", resindex); // 更改ID
        vm.$set(vm.messages[index], "ok", 1); // 更改ID
        vm.$set(vm.messages[index], "deny", 0); // 更改ID
        // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
        this.isTextDisplayed = true;
        this.$nextTick(() => {
          const container = this.$refs.messageContainer;
          container.scrollTop = container.scrollHeight;
        });
      };
      printFunction();
    },

    // 复制
    copy(message) {
      if (message.copy === 0) {
        message.copy = 1;
      }
      // console.log(message)
      const textToCopy = message.answer;
      const el = document.createElement("textarea");
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message({
        message: this.$t('ai.replicatingTip'),
        type: "success",
      });
    },
    // 点否提交
    submitDeny() {
      this.submitForm.answer = this.denyForm.answer;
      this.denyformShow = false;
      console.log(this.denyForm);
      console.log(this.submitForm);
      // 假设您有一个要更新的 message 对象
      this.messages = this.messages.map((message) => {
        if (message.id === this.submitForm.id) {
          message.deny = 1; // 将匹配条件下的 message 的 deny 属性设置为 1
        }
        return message; // 返回更新后的 message
      });

      console.log(this.messages); // 打印更新后的 messages 数组

      this.$axios
        .put(
          "/api/qa/update",
          {
            id: this.submitForm.id,
            qaEvaluationContent: this.denyForm.answer,
            qaAnswers: this.denyForm.answer,
            qaQuery: this.submitForm.primary,
            qaEvaluate: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    // 点否重置
    resetDeny() {
      this.denyForm.question = this.submitForm.question;
      this.denyForm.answer = this.submitForm.primary;
    },
    // 点否点击
    denyChange(event) {
      // 获取按钮自带的 data-info 参数
      const md = new MarkdownIt();

      if (event.deny === 0) {
        this.denyformShow = true;
        this.submitForm.question = event.question;
        this.submitForm.answer = event.answer;
        this.submitForm.primary = this.removeHtmlTags(md.render(event.answer));
        this.submitForm.id = event.id;
        this.denyForm.question = this.submitForm.question;
        this.denyForm.answer = this.submitForm.primary;
      } else {
        this.messages = this.messages.map((message) => {
          if (message.id === this.submitForm.id) {
            message.deny = 0; // 将匹配条件下的 message 的 deny 属性设置为 1
          }
          return message; // 返回更新后的 message
        });

        console.log(this.messages); // 打印更新后的 messages 数组
      }
    },
    login() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
    },
    hidePlaceholder() {
      if (this.userInfo.userId) {
        this.hasPlaceholder = false;
        this.$refs.inputArea.focus();
      }
    },
    blurHandle() {
      if (this.$refs.inputArea.value) {
        this.hasPlaceholder = false;
      } else {
        this.hasPlaceholder = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .el-form-item__label{
    font-size: 1vw;
  }
}
.avator {
  border: 2px solid #fff;
}
::v-deep .el-dialog__body{
  padding: 1vw 1vw 3vw;
}
.userName {
  color: #96bff9;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ai-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.total {
  display: flex;
  flex: 1;
  overflow: hidden;
  .left {
    position: relative;
    width: 17vw;
    background: linear-gradient(to right, #d8e4fa 0%, rgb(250, 247, 247) 100%);
    //border-right: 1px solid #bdbdbd;
    .imgStyle {
      height: 88px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size: 2.5vw;
      font-family: 方正舒体;
      font-weight: bold;

      .img {
        margin-top: -2vh;
        width: fit-content;
        height: 33px;
      }
    }

    .spanStyle {
      width: 100%;
      height: 1px;
      background-color: rgba(44, 62, 80, 0.1);
    }

    .button {
      height: fit-content;
      padding: 3vh 2vw 1vh 2vw;

      .newSession {
        background-color: #85aefa;
        color: white;
        cursor: pointer;
        height: 48px;
        font-family: "PingFang SC", "Microsoft YaHei", SimHei;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0px;
        border-radius: 5px;
        margin-bottom: 2vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .newSession:hover {
        background-color: #4484fd;
      }
    }

    .history {
      width: fit-content;
      padding: 2vh 2vw 1vh 2vw;
      font-size: 0.8vw;
      color: gray;
    }

    .appreciate {
      display: flex;
      width: fit-content;
      padding: 1.2vh 7px 1vh 15px;
      justify-content: center;
      align-items: center;
      font-size: 0.8vw;
      color: gray;
    }

    .no {
      display: flex;
      width: fit-content;
      padding: 1.2vh 2vw 1vh 7px;
      font-size: 0.8vw;
      justify-content: center;
      align-items: center;
      color: gray;
    }

    li {
      list-style-type: none;
    }

    .historycontendNo {
      border: none;
      font-size: 1vw;
      font-weight: normal;
      min-height: 55vh;
      max-height: 55vh;
      height: auto;
      overflow-x: hidden;
      word-wrap: break-word; //换行
      text-align: center; /* 文本水平居中对齐 */
      bottom: 0;
      margin: 2vh 2vw 1vh 2vw;
      justify-content: center;

      .talkNo {
        width: 108px;
        height: 108px;
        margin: 85px auto 45px auto;
      }
    }

    .historycontend {
      border: none;
      font-size: 2vh;
      font-weight: bold;
      min-height: 55vh;
      max-height: 55vh;
      height: auto;
      overflow-x: hidden;
      word-wrap: break-word; //换行
      text-align: center; /* 文本水平居中对齐 */
      bottom: 0;
      margin: 2vh 2vw 1vh 2vw;

      .historyCard {
        position: relative;
        height: 58px;
        border-radius: 8px;
        border: rgba(44, 43, 43, 0.01) 2px solid;
        margin-bottom: 5px;
        width: 98%;
        display: flex;
      }

      ::v-deep .Historyimg {
        // padding-left: 5px;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        border-radius: 100%;
        overflow: hidden;
        border: none;
        // background-image: url("../../assets/AIAnswer/comelogo.png");
        // background-size: contain;
        // background-position: center;
        // background-repeat: no-repeat;
      }

      .historydelete {
        display: none;
        position: absolute;
        right: 10px;
        color: #2f6eff;
        font-size: 18px;
        margin-top: 5px;
        width: 35px;
        justify-content: center;
      }

      .HistoryCon {
        margin: 8px 2px 0 15px;
        width: 135px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word; //换行
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .historyCard:hover {
        border: #2f6eff 1px solid;
        background-color: white;

        .HistoryCon {
          color: black;
        }

        .historydelete {
          display: block;
        }
      }
    }

    .aboutTotal {
      cursor: pointer;
      position: absolute;
      bottom: 3px;
      padding: 7px 5px;
      background-color: white;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      font-family: "PingFang SC", "Microsoft YaHei", SimHei;
      font-size: 16px;
      color: gray;

      .about {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        cursor: pointer;
        color: gray;
      }
    }

    .aboutTotal:hover {
      color: #111111;
    }
  }

  .right {
    position: relative;
    width: 83vw;
    // background-color: white;

    .dragon {
      min-height: 25vh;

      .imgStyle {
        margin: 10.5vh auto 3vh auto;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 30vw;
        height: 25vh;
        background-image: url("../../assets/AIAnswer/dragon.jpg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        animation: floatUpDown 5s infinite alternate; /* Change the duration and other properties as needed */
      }

      @keyframes floatUpDown {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(
            -20px
          ); /* Adjust this value to control the float distance */
        }
        100% {
          transform: translateY(0);
        }
      }
    }

    .span {
      width: 45vw;
      //margin: 8px auto 4px auto;
      height: fit-content;
      padding: 8px 15px 10px 15px;
    }

    .spanStyle {
      margin-left: -20px;
      width: 120%;
      height: 1px;
      border: #f6f5f5 1px solid;
    }

    .normal {
      width: 100%;
      margin: 5px auto 0 auto;
      border-radius: 9px;
      border: 2px solid white;
      //background-color: #d8e4fa;

      height: fit-content;
      padding: 10px 15px;

      .title {
        font-family: "PingFang SC", "Microsoft YaHei", SimHei;
        font-weight: 400;
        font-size: 0.9vw;
        line-height: 1.5vw;
        // text-align: justify;
        a {
          color: #064fe5;
        }
      }

      .Answer {
        margin: 15px 10px 5px 0;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 10px;
        column-gap: 10px;

        .serve {
          background: linear-gradient(
            to right,
            #d8e4fa 0%,
            rgb(250, 247, 247) 100%
          );
          font-family: "PingFang SC", "Microsoft YaHei", SimHei;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0px;
          border-radius: 9px;
          padding: 10px 10px;
          cursor: pointer;
        }

        .serveActive {
          background: linear-gradient(
            to right,
            #98b8f3 0%,
            rgb(250, 247, 247) 100%
          );
          border: white 1px solid;
          box-shadow: 0 2px 14px 0 #abc4f3;
          font-family: "PingFang SC", "Microsoft YaHei", SimHei;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0px;
          border-radius: 9px;
          padding: 10px 10px;
          cursor: pointer;
        }

        .serve:hover {
          border: #d8e4fa 1px solid;
          background-color: white;
          cursor: pointer;
        }

        .serveActive:hover {
          border: #abc4f3 1px solid;
          background-color: white;
          cursor: pointer;
        }
      }
    }

    .news1 {
      // height: 100vh;
      height: 100%;
      width: 83vw;
      padding: 5vh 3vw 5vh 1vw;
      background: linear-gradient(
        to right,
        rgb(241, 243, 250) 0%,
        rgb(250, 247, 247) 100%
      );

      .denyForm {
        overflow-y: hidden;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        background-color: white;
        width: 320px !important;
        height: 350px;
        border-radius: 15px;
        position: absolute;
        padding-bottom: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

        ::v-deep .el-input {
          font-size: 1vw;
        }

        .questioninput {
          //overflow: auto; /* 添加滚动条 */
          max-height: 8vh;
          width: 40vw;
          height: auto;
          border-radius: 5px;
          padding: 5px;
          margin-left: 5px;
          font-size: 1vw;
          border: black 2px solid;

          .el-textarea__inner {
            height: 5vh;
          }
        }

        .ansinput {
          width: 40vw;
          margin-left: 6px;
          height: auto;
          border-radius: 5px;
          padding: 5px;
          min-height: 5vh;
          overflow: auto; /* 添加滚动条 */
        }
      }

      .content {
        position: relative;
        width: 100%;
        //padding: 2% 2% 1% 2%;
        height: 100%;
        border-radius: 0;
        overflow: hidden;
        //background: #f7f7f9;
      }

      .communicate {
        border-radius: 0;
        // min-height: 75vh;
        // max-height: 75vh;
        // padding-bottom: 5vh;
        height: calc(100% - 110px);
        //background: #f7f7f9;
        font-size: 0.9vw;
        overflow-y: auto;
        //border: 5px solid black;
      }

      .atalk {
        //margin: 5px;
        //padding: 5px;
        display: flex;
        //background-color: white;
        position: relative;
        align-items: flex-start; /* Align items at the top */
      }

      .btalk {
        margin: 2px;
        right: 0 !important;
        display: flex;
        flex-direction: column;
        flex-grow: 8;
      }

      .AIphotoMove {
        min-width: 2vw;
        background-image: url("../../assets/AIAnswer/AIphoto.png");
        width: 2vw;
        height: 2vw;
        //margin-right: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 20%;
        //margin-top: 5px;
        font-size: 8px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse 2s infinite;
      }

      @keyframes pulse {
        0% {
          transform: scale(1); /* 原始大小 */
        }
        50% {
          transform: scale(1.1); /* 放大到120% */
        }
        100% {
          transform: scale(1); /* 恢复到原始大小 */
        }
      }

      .AIphoto {
        min-width: 40px;
        background-image: url("../../assets/AIAnswer/AIphotoNo.png");
        width: 40px;
        height: 35px;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 20%;
        margin-top: 5px;
        font-size: 8px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .userPhoto {
        //background-image: url("../../../assets/AIAnswer/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
        width: 40px;
        height: 40px;
        //margin-left: 8px;
        font-size: 1vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        margin-top: 5px;
      }

      .arrow-content {
        margin-left: 15px;
        padding: 14px 11px;
        //display: inline-block;

        display: flex;
        align-items: center;
        color: #000;
        min-height: 65px;
        margin-right: 0;
        font-size: 1vw;
        font-style: normal;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .con_que {
        margin: 0 15px;
        background-color: #e9e9f2;
        padding: 15px;
        border-radius: 5px;
        color: #1c88ff;
        width: 95%;
        height: fit-content;
        line-height: 1.3vw;
        cursor: pointer;
      }

      .czkj-question-msg {
        font-size: 1vw;
        color: #3163c5;
      }

      .czkj-question-msg:hover {
        color: #064fe5;
        font-weight: bold;
      }

      .answerPrimary {
        width: 100%;
        min-height: 55px;
        margin-left: 1vw;
        position: relative;
        background-color: rgba(255, 255, 255);
        border-radius: 8px;
        height: fit-content;

        .tips {
          border: 1px solid #ffffff;
          background-color: white;
          height: 50px;
          width: fit-content;
          padding: 15px;
          margin-left: 15px;
        }
      }

      .answer {
        //min-height: 55px;
        margin-left: 15px;
        position: relative;
        background-color: rgba(255, 255, 255);
        border-radius: 8px;
        height: fit-content;

        .tips {
          border: 1px solid #ffffff;
          background-color: white;
          height: 50px;
          width: fit-content;
          padding: 15px;
          margin-left: 15px;
        }
      }

      .talk {
        text-align: justify;
        //border: 1px solid black;
        padding: 14px 11px;
        display: inline-block;
        color: #000;
        //margin: 0 8px 0 15px;
        font-size: 0.9vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5vw;
        letter-spacing: 0.5px;

        //border: #2c3e50 1px solid; width: 100%; padding: 5px 10px; word-wrap: break-word; position: relative; /* Add this */
      }

      .afterAnswer {
        margin-top: 5px;
        border-top: 1px solid #bebebe;
        padding: 15px 5px;
        display: flex;
        height: fit-content;
        justify-content: space-between;
      }

      .dataSource {
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3vw;
        letter-spacing: 0.5px;
        margin-right: 15px;
        margin-left: 50px;
      }

      .copy {
        border: none;
        background-color: white;
        display: block;
        cursor: pointer;
        background-image: url("../../assets/AIAnswer/copy.png");
        width: 20px;
        height: 20px;
        margin: auto 7px auto 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .copy:hover {
        transform: scale(1.1);
      }

      .copyReally {
        border: none;
        background-color: white;
        margin: auto 7px auto 20px;
        display: block;
        cursor: pointer;
        background-image: url("../../assets/AIAnswer/copyReally.png");
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .copyReally:hover {
        transform: scale(0.9);
      }

      .deny {
        border: none;
        background-color: white;
        display: block;
        cursor: pointer;
        background-image: url("../../assets/AIAnswer/deny.png");
        width: 22px;
        height: 20px;
        margin: auto 7px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .deny:hover {
        transform: scale(1.1);
      }

      .denyReally {
        border: none;
        background-color: white;
        margin: auto 7px;
        display: block;
        cursor: pointer;
        background-image: url("../../assets/AIAnswer/denyReally.png");
        width: 22px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .denyReally:hover {
        transform: scale(0.9);
      }

      .gray {
        border: none;
        background-color: white;
        display: block;
        cursor: pointer;
        background-image: url("../../assets/AIAnswer/gray.png");
        width: 22px;
        height: 20px;
        margin: auto 7px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .gray:hover {
        transform: scale(1.1);
      }

      .grayReally {
        border: none;
        background-color: white;
        margin: auto 7px;
        display: block;
        cursor: pointer;
        background-image: url("../../assets/AIAnswer/greeReally.png");
        width: 22px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .grayReally:hover {
        transform: scale(0.9);
      }

      .talk_input {
        overflow: visible;
        display: flex; /* 启用Flexbox布局 */
        flex-direction: column; /* 子元素垂直排列 */
        align-items: center; /* 拉伸子元素以填充容器 */
        justify-content: center;
        //margin-left: 70px;
        min-height: 3vw;
        border-radius: 8px;
        //box-shadow: 0 2px 0 2px rgba(100, 98, 98, 0.1);
        border: rgba(44, 62, 80, 0.1) 1px solid;
        background-color: white;
        position: absolute;
        bottom:0;
        margin-left: 3vw;
        width: calc(100% - 3vw);
      }

      .input_container {
        height: 3vw;
        position: relative;
        display: flex; /* 使用 Flexbox 布局 */
        align-items: center; /* 垂直居中 */
        width: 100%;
        .self-placeholder-box {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          background-color: transparent;
          .self-placeholder-text {
            padding-left: 28px;
            font-size: 1vw;
            color: rgba(0, 0, 0, 0.58);
            a {
              color: #064fe5;
              cursor: pointer;
            }
          }
        }
      }

      .chat-num-tip {
        font-size: 13px;
        color: #888;
        width: 100%;
        padding: 10px 0;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-36px);
        .buy {
          color: blue;
          cursor: pointer;
        }
      }

      .talk_word {
        resize: vertical; /* 允许垂直调整大小 */
        min-height: 1.3vw;
        height: auto;
        overflow-y: hidden;
        max-height: 200px;
        padding: 0 5px 0 15px;
        outline: none;
        width: 100%;
        margin: 10px auto;
        text-indent: 10px;
        border: none;
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3vw;
        letter-spacing: 0.5px;
      }

      .talk_button {
        display: flex;
        justify-content: space-between; /* 子元素之间的间隔平均分布 */
        cursor: pointer; /* 鼠标变成手型 */
        width: fit-content;

        //padding: 15px;
        margin: auto 1vw auto auto;
      }

      .talk_web {
        cursor: pointer; /* 鼠标变成手型 */
        width: 35px;
        height: 35px;
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-image: url("../../assets/AIAnswer/network.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: white;
      }

      .talk_webNo {
        cursor: pointer; /* 鼠标变成手型 */
        width: 35px;
        height: 35px;
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-image: url("../../assets/AIAnswer/neeNo.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: white;
      }

      .talk_file {
        cursor: pointer; /* 鼠标变成手型 */
        width: 35px;
        height: 35px;
        padding: 15px;
        border: none;
        border-radius: 10px;
        background-image: url("../../assets/AIAnswer/fileUpload.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: white;
      }

      .talk_sub {
        cursor: pointer; /* 鼠标变成手型 */
        width: 2vw;
        height: 2vw;
        padding: 1vw;
        border: none;
        border-radius: 10px;
        background-image: url("../../assets/AIAnswer/send.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: white;
      }
      .talk_sub:hover {
        background-image: url("../../assets/AIAnswer/sendReally.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .talk_input:active {
        border: #c9dbfd 2px solid;
      }

      .talk_input:hover {
        border: #d8e4fa 2px solid;
      }

      .notify-success {
        top: 1.7rem !important;
        right: 0.32rem !important;
        width: 150px !important;
        height: 0.96rem !important;
        background: rgba(131, 213, 134, 0.1) !important;
        border-radius: 0.04rem 0px 0px 0.04rem !important;
      }
    }
  }
}

/* 隐藏滚动条，但保留滚动功能 */
//::-webkit-scrollbar {
//  display: none;
//}

.historycontend::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}

.historycontend::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}

.historycontend::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}

.historycontend::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.historycontend::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

.communicate::-webkit-scrollbar {
  display: none;
}

.communicate::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}

.communicate::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}

.communicate::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}

.communicate::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.communicate::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

/* 针对IE浏览器，添加滚动条样式 */
communicate {
  scrollbar-face-color: #888; /* 滚动条滑块的颜色 */
  scrollbar-track-color: #ffffff; /* 滚动条轨道的颜色 */
  scrollbar-arrow-color: #888; /* 滚动条箭头的颜色 */
  scrollbar-shadow-color: #f1f1f1; /* 滚动条阴影的颜色 */
  scrollbar-highlight-color: #f1f1f1; /* 滚动条高亮区域的颜色 */
  scrollbar-3dlight-color: #f1f1f1; /* 滚动条3D高光的颜色 */
  scrollbar-darkshadow-color: #f1f1f1; /* 滚动条3D阴影的颜色 */
}

historycontend {
  scrollbar-face-color: #888; /* 滚动条滑块的颜色 */
  scrollbar-track-color: #ffffff; /* 滚动条轨道的颜色 */
  scrollbar-arrow-color: #888; /* 滚动条箭头的颜色 */
  scrollbar-shadow-color: #f1f1f1; /* 滚动条阴影的颜色 */
  scrollbar-highlight-color: #f1f1f1; /* 滚动条高亮区域的颜色 */
  scrollbar-3dlight-color: #f1f1f1; /* 滚动条3D高光的颜色 */
  scrollbar-darkshadow-color: #f1f1f1; /* 滚动条3D阴影的颜色 */
}
</style>

