import { render, staticRenderFns } from "./AIAnswer.vue?vue&type=template&id=26832eb5&scoped=true"
import script from "./AIAnswer.vue?vue&type=script&lang=js"
export * from "./AIAnswer.vue?vue&type=script&lang=js"
import style0 from "./AIAnswer.vue?vue&type=style&index=0&id=26832eb5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26832eb5",
  null
  
)

export default component.exports